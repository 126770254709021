<script lang="ts" setup>
const props = defineProps<{
  required?: boolean
  disabled?: boolean
  dataCy?: string
  color?: string
  label?: string
}>()

const modelValue = defineModel<boolean>({ required: true })

const computedColor = computed(() => props.color ?? 'primary')

const rules = computed(() => {
  if (props.required) {
    return [Boolean]
  } else {
    return []
  }
})

const themeInfo = useTheme()

const colorValue = computed(() => {
  const theme = themeInfo.themes.value.light
  return theme.colors[computedColor.value]
})
</script>

<template>
  <v-checkbox
    v-model="modelValue"
    class="skr-checkbox"
    true-icon="custom:checkbox_active"
    false-icon="custom:checkbox_inactive"
    hide-details
    :ripple="false"
    :disabled="disabled"
    :color="computedColor"
    :rules="rules"
    :data-cy="dataCy"
  >
    <template #label>
      <span :class="['skr-checkbox__label', { 'skr-checkbox__label--active': modelValue }]">
        <span v-if="label">{{ label }}</span>
        <slot v-else />
      </span>
    </template>
  </v-checkbox>
</template>

<style lang="scss" scoped>
.skr-checkbox {
  --v-input-control-height: 24px !important;

  :deep(.v-input__control) {
    .v-label:empty,
    .skr-checkbox__label:empty {
      display: none;
    }

    .v-selection-control {
      align-items: center;
      transition: color 0.2s cubic-bezier(0.4, 0, 0.6, 1);

      &__wrapper {
        --v-selection-control-size: 24px;
      }

      .skr-checkbox__label {
        padding-left: 0.5rem;
        &--active {
          font-weight: 700;
          color: v-bind(colorValue);
        }
      }

      &:hover {
        color: v-bind(colorValue);
      }
    }
  }
}
</style>
